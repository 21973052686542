import axios from 'axios';
import authHeader from './auth-header';
import constants from '../constants';
import { parseError } from '@/utils/utils'
var API_URL = constants.API_URL + 'project/';

class ProjectService {
  async createProject(data) {
    try {
      let response = await axios.post(API_URL + 'createProject', data, { headers: authHeader() });
      return response;
    }
    catch (error) {
      return parseError(error);
    }
  } 

  async changeValue(projectId, valueName, newValue, language) {
    return await axios.post(API_URL + 'updateProjectValue', {id: projectId, valueName: valueName, value: newValue, locale: language}, { headers: authHeader() });
  }

  async changeAddress(projectId, addressId, changes) {
    try {
      await axios.post(API_URL + 'updateAddress', {id: addressId, projectId: projectId, changes: changes}, { headers: authHeader() });
    }
    catch (error) {
      console.error(parseError(error));
    }
  }

  async createRequest(projectId, requestType, language) {
    try {
      return await axios.post(API_URL + 'createRequest', {projectId: projectId, requestType: requestType, locale: language}, { headers: authHeader() });
    }
    catch (error) {
      console.error(parseError(error));
      return {
        error: error
      }
    }
  }

  async updateStatusProject(projectId, statusEnum) {
    try {
      let response = await axios.post(API_URL + 'updateStatusProject', { projectId: projectId, statusEnum: statusEnum }, { headers: authHeader() });
      return response;
    }
    catch (error){
      return parseError(error);
    }
  }
}

export default new ProjectService();
